<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle"></banner>

    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>产品展示</span>
			</div>
			<ul class="nav_left">
				<li :class="{'active':type == 1}">
					<a @click="checkType(1)">在线监测设备</a>
				</li>
				<li :class="{'active':type == 2}">
					<a @click="checkType(2)">便携检测设备</a>
				</li>
				<li :class="{'active':type == 3}">
					<a @click="checkType(3)">卵囊藻自动扩培机</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a @click="checkType(1)">产品中心</a> > <a>{{ productName}}</a> </span>
			</div>
     
			<ul  class="pro_zi_ul clearfix">
			  <li v-if="type == 1">
					  <a @click="productDetail(1)" class="pic">
						  <img src="../assets/images/2019011516585676944CFEF7C8527F_s.jpg" alt="" class="vcenter" />
						  <i></i>
					  </a>
					  <div class="text">
						  <p>Y53水产在线监测控制仪</p>
						  <a  @click="productDetail(1)">VIEW MORE+</a>
					  </div>
				</li>
        <li v-if="type == 2">
					 <a  @click="productDetail(2)" class="pic">
						<img src="../assets/images/20231213172302674802DDC609611B_s.png" alt="" class="vcenter" />
						<i></i>
					</a>
					<div class="text">
						<p>P3便携式水质检测仪</p>
						<a  @click="productDetail(2)">VIEW MORE+</a>
					</div>
				</li>
        <li v-if="type == 3">
					  <a @click="productDetail(3)" class="pic">
						<img src="../assets/images/202005271628259BF180964ED5DCA3_s.jpg" alt="" class="vcenter" />
						<i></i>
					</a>
					<div class="text">
						<p>L201卵囊藻自动扩培机</p>
						<a  @click="productDetail(3)">VIEW MORE+</a>
					</div>
				</li>
			</ul>
      
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,    
  },
  beforeRouteUpdate(to,from,next){
    console.log(111111)
     this.detail = false;
    if(to.params.type != this.type){
      this.type = to.params.type;
    }
    next()
  },

  data(){
    return {
      bannerUrl: ["20190115155508a53283.jpg"],
      type: 1,
      productName:'在线监测设备',
      divStyle: '',
      imgStyle: '',
    }
  },
  watch: {
    type(item1,item2){
      console.log("++++++++++++")
      if(item1 != item2){
        if(this.type == 1){
          this.productName = '在线监测设备'
        }else if(this.type == 2){
          this.productName = '便携检测设备'
        }else if(this.type == 3){
          this.productName = '卵囊藻自动扩培机'
        }
      }
    },
  },
  created(){
    if(this.$route.params){
      this.type = this.$route.params.type;
    }
  },
  methods: {
    checkType(type){
      this.type = type;
    },
    productDetail(type){
     this.$router.push({
          path:"/productDetail/"+type
      })
    }
  }
}
</script>
  
<style>

    
    

</style>