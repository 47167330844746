<template>
   	<section class="banner">
	    <div v-for="item in bannerUrl" :key="item" :style=divStyle>
            <img :style=imgStyle :src=getUrl(item) alt="">
            <div v-if="subtitle" style="position: absolute;left: 50%; top: 50%;
			transform: translate(-50%, -50%); ">
				<p style="text-align: center;">
					<span style="font-family: 微软雅黑, SimSun; font-size: 16px; color:#fff;">{{ bannerTitle[0] }}</span>
				</p>
				<p><span style="color: #fff;"><strong><span style="font-family: 微软雅黑, SimSun; font-size: 36px; letter-spacing: 8px;">{{ bannerTitle[1] }}</span></strong></span></p>
			</div>
        </div>
	</section>	
        
    
    
</template>
  
<script>
  
  
  export default {
    name:"MyBanner",
    props: {
        bannerUrl: {
            type: Array,
            require: true,
            default: ()=>[]
        },
        divStyle: {
            type: String,
            default: ""
        },
        bannerTitle: {
            type: Array,
            default: ()=>[]
        },
        subtitle: {
            type: Boolean,
            default: false
        },
        imgStyle: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
        
        }
    },
    mounted(){
        console.log("banner")
        this.initSlick()
    },
    computed:{
        
    },
    watch: {
         bannerUrl(item1,item2){
             console.log("banner111111")
            if(item1.toString() != item2.toString()){
                
                 console.log("slick")
                 
                // this.initSlick()
            }
            console.log(this.bannerUrl)
         }
    },
    methods: {
       getUrl(item){
        return require("@/assets/images/"+item)
       },
       initSlick(){
            $('.banner').slick({
		        dots: true,
		        autoplay:true,
		        arrows:false,
		        autoplaySpeed:3000,
		});
       }
    },
  }
</script>
  
<style scoped>
.nav{
    width: 100%;
    background-position: center center;
    height: 502px;
    background-repeat: no-repeat;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
        
}
.nav p{
    font-size: 40px;
    color: white;
    margin: 0;
    text-align: center;
    
}
.nav p:last-child{
    font-size: 30px;
    margin-top: 30px;
}
.nav div{
    margin-top: -15px;
}
    
    

</style>