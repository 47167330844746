import { createRouter ,createWebHashHistory } from "vue-router";
import Index from "../pages/indexpage.vue"
import Product from "../pages/productpage.vue"
import New from "../pages/newspage.vue"
import AboutUs from "../pages/aboutpage.vue"
import Contentus from "../pages/contentus.vue"
import ProductDetail from "../components/main/productDetail.vue"
import Data from "../pages/datapage.vue"
import DataDetail from "../components/main/dataDetail.vue"
import Case from "../pages/casepage.vue"
import NewsDetail1 from "../components/main/newsDetail1.vue"
import NewsDetail2 from "../components/main/newsDetail2.vue"
import CaseDetail from "../components/main/caseDetail.vue"
const routes=[
    {
        path:"/index",
        component:Index
    },
    {
        path:"/",
        redirect:"/index"
    },
    {
        path:"/product/:type",
        component:Product
    },
    {
        path:"/new/:type",
        component:New
    },
    {
        path:"/about",
        component:AboutUs
    },
    {
        path:"/contentus",
        component:Contentus
    },
    {
        path:"/productDetail/:type",
        component:ProductDetail
    },
    {
        path:"/data",
        component:Data
    },
    {
        path:"/dataDetail/:index",
        component:DataDetail
    },
    {
        path:"/case/:type",
        component:Case
    },
    {
        path:"/newsDetail/:index",
        component:NewsDetail1
    },
    {
        path:"/newsDetail2/:index",
        component:NewsDetail2
    },
    {
        path:"/caseDetail/:type",
        component:CaseDetail
    },
    
]
export default createRouter({
    routes,
    history:createWebHashHistory()
})
