<template>
    	<div class="footer">
		<div class="footer_com clearfix">
			<ul class="di_nav">
				<li style="height: 150px;vertical-align: middle;width: 50%;border-left: none;display: flex;justify-content: center;align-items: center;">
					<img src="../../assets/images/2019011115382555d737.png" alt="" >	
				</li>
				<li >
					<a class="tit">联系我们</a>
					<p><span class="conact_message">联系电话</span>：027-59764323</p>
					<p><span class="conact_message">传真</span>：027-59764323</p>
					<p><span class="conact_message">电子邮箱</span>： zywl@zytdwl.cn</p>
					<p><span class="conact_message">地址</span>：湖北省武汉市东湖开发区光谷大道303号光谷·芯中心E2-07栋801室</p>
				</li>
			</ul>
			<div class="er_right">
				<div class="er">
					<div class="pic">
						<img src="../../assets/images/20190815150307034e29.jpg" alt="" class="vcenter">
						<i></i>
					</div>
					<div class="text">
						<span>关注微信</span>
						
					</div>
				</div>
			</div>
		</div>
		<div class="copy clearfix">
			<div class="copy_cc" style="margin-bottom: 10px;">
				<p style="text-align: center;font-size: 12px;">
					Copyright @ 中易物联 All Rights Reserved
					<span style="font-size: 16px;"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #000;text-align: center;">鄂ICP备16009298号-2</a></span>
				</p>
			</div>
		</div>
	</div>
</template>
  
<script>
  
  
export default {
   name:"MyFoot"
}
</script>
  
<style>
footer {
    height: 118px;
    width: 100%;
    background-color: #48382c;
}
footer div {
    width: 540px;
    margin: auto;
    height: 118px;
    overflow: hidden;
    

}

footer div P:first-child {
    color: white;  
    text-align: center;
    margin-top: 42px;
    font-size: 10px;
}

footer div P:last-child {
    color: #999999;
    font-size: 10px;
    margin-top: 13px;
    text-align: center;
}
    
    

</style>