<template>
    <nav>
        <div class="box">
        <div class="left">
            <p>企业概况</p>
            <p>企业文化</p>
            <p>发展历程</p>
            <p>荣誉奖项</p>
        </div>
        <div class="right">
            <p>首页 <span>></span> </p>
            <p>关于我们<span>></span></p>
            <p>企业概况</p>
        </div>
        </div>
    </nav>
</template>
  
<script>
  
  
export default {
   name:"MyNav"
}
</script>
  
<style scoped>
nav{
    width: 100%;
    height: 69px;
    border-bottom: solid 2px #e6e6e6;
    margin-bottom: 31px;
}
nav .box{
    display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: auto;
       
    
}
nav  .left{
    display: flex;
    padding-left: 25px;
    font-size: 11px;
   
   
    margin-bottom: 32px;
    
}
nav .left p{
    width: 50px;
    border-bottom: 2px solid #f57d0d;
    color: #f57d0d;;
    height: 67px;
    line-height: 67px;
    margin: 0px;
    margin-right: 57px;
    text-align: center;
}
nav .right{
    display: flex;
    padding-right: 25px;
}
nav .right p:first-child{
    
    text-align: end;
}
nav .right p{
    text-align: left;
    margin: 0px;
        height: 67px;
        line-height: 67px;
        font-size: 14px;
        color: #777;
        width: 69px;
}
nav .right p span{
    display: inline-block;
    padding: 0px 1px;
}
    
    

</style>