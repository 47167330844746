<template>
  <div>
	<banner :bannerUrl="bannerUrl"></banner>
	<div class="case_home">
		<div class="case_com">
			<div class="top">
				<span>产品中心</span>
				<i>PRODUCT DISPLAY</i> 
				<em></em>
			</div>
			<ul class="clearfix">
				<li class="clearfix">
					<div class="pic_ca">
						<img src="../assets/images/20190114142839583ba5.png" alt="" class="vcenter"/>
						<i></i>
					</div>
					<div class="text_ca">
						<p>在线监测设备</p>
					</div>
				</li>
				<li>
					<div class="pic_ca">
						<img src="../assets/images/20231213172908450cc8.png" alt="" class="vcenter" />
						<i></i>
					
					</div>
					<div class="text_ca">
						<p>便携检测设备</p>
					</div>
				</li>
				<li>
					<div class="pic_ca">
						<img src="../assets/images/201908151452131645ff.png" alt="" class="vcenter"/>
						<i></i>
					</div>
					<div class="text_ca">
						<p>卵囊藻自动扩培机</p>
					</div>
				</li>
				
			</ul>
		</div>
		<div style="margin-top: 10px;">
			<img src="../assets/images/20240119140205.png">
		</div>
		<div  class="product_home" style="margin-top: 10px;">
			<div class="product_title">4种智能交互方式</div>
			<div class="product_title_1">助您养殖旗开得胜</div>
			<ul class="clearfix">
				<li class="clearfix">
					<div class="pic_ca">
						<img src="../assets/images/20190115150637f9b267.png" alt="" style="display: block;margin: auto"/>
						<!-- <i></i> -->
						<p style="text-align: center;font-size: 18px;margin-top: 5px;">智能检测</p>
						<p style="text-align: center;font-size: 20px;padding: 15px">
						   <img src="../assets/images/201812221159313af5c4.png" alt="" style="display: block;margin: auto;"/>
					    </p>
					</div>
					<div class="text_ca">
						<p>智能检测水质各项参数，知己知彼</p>
					</div>
				</li>
				<li class="clearfix">
					<div class="pic_ca">
						<img src="../assets/images/201901151507421cc8b3.png" alt="" style="display: block;margin: auto"/>
						<!-- <i></i> -->
						<p style="text-align: center;font-size: 18px;margin-top: 5px;">智能分析</p>
						<p style="text-align: center;font-size: 20px;padding: 15px">
						   <img src="../assets/images/201812221159313af5c4.png" alt="" style="display: block;margin: auto;"/>
					    </p>
					</div>
					<div class="text_ca">
						<p>水质智能评分，分析报告，建议方案</p>
					</div>
				</li>
				<li class="clearfix">
					<div class="pic_ca">
						<img src="../assets/images/20190115150833728311.png" alt="" style="display: block;margin: auto"/>
						<!-- <i></i> -->
						<p style="text-align: center;font-size: 18px;margin-top: 5px;">智能控制</p>
						<p style="text-align: center;font-size: 20px;padding: 15px">
						   <img src="../assets/images/201812221159313af5c4.png" alt="" style="display: block;margin: auto;"/>
					    </p>
					</div>
					<div class="text_ca">
						<p>智能/定时/远程控制增氧机、投饵机等设备</p>
					</div>
				</li>
				<li class="clearfix">
					<div class="pic_ca">
						<img src="../assets/images/201901151509365deb6f.png" alt="" style="display: block;margin: auto"/>
						<!-- <i></i> -->
						<p style="text-align: center;font-size: 18px;margin-top: 5px;">智能管理</p>
						<p style="text-align: center;font-size: 20px;padding: 15px">
						   <img src="../assets/images/201812221159313af5c4.png" alt="" style="display: block;margin: auto;"/>
					    </p>
					</div>
					<div class="text_ca">
						<p>水产养殖数字化，水质/投饵/用药科学管理</p>
					</div>
				</li>
			</ul>
		</div>

	</div>
	<div class="wrap container">
         <!-- <div class="top">
            <span>关于我们</span>
             <i>THE PERMANENT WORKS</i>
            <em></em>
        </div> -->
    	<dl class="i_about clearfix">
        	<dt class="col-md-6 col-sm-6 col-xs-12">
        		<img src="../assets/images/20190115162113a54a9e.jpg" alt="">
        	</dt>
            <dd  class="col-md-6 col-sm-6 col-xs-12">
            	<h3>关于我们</h3>
                <p  style="text-indent: 2em;">武汉市中易天地物联科技有限公司是一家以智慧渔业云平台为核心产品，为水产行业提供物联网一站式系统解决方案（简称中易智慧渔业系统）的高新技术企业。
						同时为客户提供在线智能水质监测仪、数据分析及解决方案等产品和服务。
						公司独立研发了拥有自主知识产权的中易智慧渔业系统，创新性将物联网技术应用到水产养殖领域，形成了集水质在线监测
						智能控制和数据分析为一体的系统化解决方案。针对水产养殖风险大、水产品质量安全隐患等行业痛点，通过对溶解氧、PH值、水温、氨氮、
						叶绿素等多项参数的数据收集及系统分析，实现水产养殖精准控制，改变以往“靠经验、靠人力、靠天气”的养殖现状，实现水产行业向智慧养殖升级。  
				</p>
                <span>公司理念</span>
                <p>使命：创新人类健康生活
				<br />
				愿景：成为全球领先的安全水产品服务商!
			   <br/>
			   期望：
让每一个池塘水波荡漾、鱼肥虾欢，
让每一处水面都成为一道美丽的风景!
			</p>
                <!-- <span>涉及行业</span>
                <p>RFID标签、产品不干胶标签、吊牌、服装吊粒及布标</p> -->
            </dd>
        </dl>
    </div>
	<div  class="project_home" style="margin-top: 10px;">
		<ul class="clearfix">
			<li class="clearfix">
				<div @click="caseDetail(1)" class="pic_ca">
					<img src="../assets/images/2019011515285090ae79.jpg" alt="" style="display: block;margin: auto"/>
					<img src="../assets/images/2019011515393242d7bd.jpg" alt="" style="display: block;margin: auto"/>
					<!-- <i></i> -->
				</div>
			</li>
			<li class="clearfix">
				<div @click="caseDetail(2)" class="pic_ca">
					<img src="../assets/images/20240223092855.jpg" alt="" style="display: block;margin: auto"/>
					<img src="../assets/images/2019011515414162e88b.jpg" alt="" style="display: block;margin: auto"/>
						<!-- <i></i> -->
				</div>
			</li>
			<li class="clearfix">
				<div @click="caseDetail(3)" class="pic_ca">
					<img src="../assets/images/20190115153637b64294.jpg" alt="" style="display: block;margin: auto"/>
					<img src="../assets/images/2019011515430696872f.jpg" alt="" style="display: block;margin: auto"/>
						<!-- <i></i> -->
				</div>
			</li>
		
		</ul>
	</div>
	<div style="margin-top: 20px;">
		<img src="../assets/images/20240109163908.png" alt="" style="display: block;margin: auto"/>
	</div>
	
<div style="padding-top: 20px;">
<div class="container nes">
	<div class="row">
		<div class="top">
			<span>新闻中心</span>
			<i>NEWS CENTER</i>  
			<em></em>
		</div>
		<div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
			<div id="news_slide" class="news_slide_box">
				<div class="bd">
					<div class="tempWrap">
						<ul >
							<li>
								<a class="pic" @click="newsDetail(1)" href="#" set_data=1>
									<img src="../assets/images/20231214172220ee97cd.jpg">
									<span class="title">
										新闻在线
									</span>
								</a>
							</li>
							<li>
								<a class="pic" @click="newsDetail(2)" href="#" set_data=2>
									<img src="../assets/images/20211227173014b958cc.jpg">
									<span class="title">
										聚力远航 蝶变升华 ——水世纪集团“951计划”启动
									</span>
								</a>
							</li>
							<li>
								<a class="pic" @click="newsDetail(3)" href="#" set_data=3>
									<img src="../assets/images/2021111009575491f2e6.png">
									<span class="title">
										克氏原螯虾产业技术体系首席科学家唐建清研究员来厂参观指导
									</span>
								</a>
							</li>
						    <li>
								<a class="pic" @click="newsDetail(4)" href="#" set_data=4>
									<img src="../assets/images/2021101917221029442f.jpg">
									<span class="title">
										热烈欢迎！荆州高新区黄星书记来武汉总部考察交流
									</span>
								</a>
							</li>
							<li>
								<a class="pic" @click="newsDetail(5)" href="#" set_data=5>
									<img src="../assets/images/202009071357083546bf.png">
									<span class="title">
										荆州高新区“十四五”发展规划课题小组实地考察调研武汉中易智能设备生产基地
									</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<span class="prev"></span>
				<span class="next"></span>
			</div>
		</div>
					<div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
						<ul class="news_list">
							<li  @click="newsDetail(1)">
								<div class="news_pic col-lg-3 col-md-3 col-sm-4 col-xs-12">									
									<a  class="">
										<img src="../assets/images/20231214172220ee97cd.jpg">
									</a>
								</div>
								<div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<a  class="news_tit">新闻在线</a>
									<p class="news_time">2023-12-14</p>
									<p class="news_desc">【1】10月9日，设备事业部总经理余总、商务经理熊浚与中国电信股份有限公司丹江口分公司魏总在丹江口市裕水鲜生态农业科技有限公司董事长沈总邀请下，参观了裕水鲜设施渔业基地，余总对裕水鲜设施渔业基地的建设提出了一些建设性意见和思路，沈总对中易物联的专业性表示认可，为接下来双方的合作打下了良好的基础。</p>
								</div>
							</li>
							<li @click="newsDetail(2)">
								<div class="news_pic col-lg-3 col-md-3 col-sm-4 col-xs-12">									
									<a  class="">
										<img src="../assets/images/20211227173014b958cc.jpg">
									</a>
								</div>
								<div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<a  class="news_tit">聚力远航 蝶变升华 ——水世纪集团“951计划”启动</a>
									<p class="news_time">2021-12-27</p>
									<p class="news_desc">十五载征程，世纪再启航！2021年12月21日上午9点整，让人期待的的水世纪集团951计划高绩效运营体系启动仪式在集团总部会议室拉开帷幕。</p>
								</div>
							</li>
							<li @click="newsDetail(3)">
								<div class="news_pic col-lg-3 col-md-3 col-sm-4 col-xs-12">									
									<a  class="">
										<img src="../assets/images/2021111009575491f2e6.png">
									</a>
								</div>
								<div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<a  class="news_tit">克氏原螯虾产业技术体系首席科学家唐建清研究员来厂参观指导</a>
									<p class="news_time">2021-11-10</p>
									<p class="news_desc" >2021年10月14日江苏省淡水水产研究所、江苏省克氏原鳌虾产业技术体系首席专家唐建清老师在集团李总和集团运营总经理杨总的陪同下，参观了荆州中易的智能设备生产基地。</p>
								</div>
							</li>
							
						</ul>
					</div>
				</div>
	</div>
</div>
   
  </div>
</template>
  
<script>
 
  
  import indexnav from "@/components/nav/indexnav.vue"
  import banner from "@/components/header/banner.vue"


export default {
   name:"IndexPage",
   components:{
    indexnav,
	banner
   },
   data(){
    return{
		bannerUrl:["20220322175535b1fbc5.png","20231214171925e14f3e.jpg"]
    }
   },
   mounted(){
  	 TouchSlide({ 
			slideCell:"#news_slide",
			mainCell:".bd ul",
			effect:"leftLoop",
			autoPlay:true//自动播放
	 });
	 document.addEventListener('click', evnt => {
		let anchor = evnt.target.closest('a[href^="#"]')
		if (anchor) {
			evnt.preventDefault()
			let value = anchor.getAttribute('set_data');
		    if(value){
 			  this.newsDetail(value)
			}
           
        }
        })
 },
 methods: {
	 newsDetail(index){
		console.log(11111)
        this.$router.push({
          path:"/newsDetail/"+index
        })
     },
	 caseDetail(type){
     this.$router.push({
          path:"/caseDetail/"+type
      })
    }
 }
    
}
</script>
  
<style>

    
    

</style>