<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>
    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>新闻中心</span>
			</div>
			<ul class="nav_left">
				<li :class="{'active':type == 1}">
					<a @click="checkType(1)">公司动态</a>
				</li>
				<li :class="{'active':type == 2}">
					<a @click="checkType(2)">行业新闻</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a >新闻中心</a> > <a >{{ productName}}</a> </span>
			</div>
     
			<ul v-if="type == 1" class="news_zi_ul clearfix">
				<li>
					<a @click="newsDetail(1)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/20231214172220ee97cd.jpg"  />
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>新闻在线</h4>
							<span style="padding-left: 25px;"> 10月9日，设备事业部总经理余总、商务经理熊浚与中国电信股份有限公司丹江口分公司魏总...</span>
						</span>
						<span class="right">
							<time>12-23</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
				<li>
					<!-- vertical-align: middle; -->
					<a @click="newsDetail(2)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/20211227173014b958cc.jpg"  />
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>聚力远航 蝶变升华 ——水世纪集团“95...</h4>
							<span style="padding-left: 25px;"> 十五载征程，世纪再启航！</span>
						</span>
						<span class="right">
							<time>12-27</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
				<li>
					<!-- vertical-align: middle; -->
					<a @click="newsDetail(3)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/2021111009575491f2e6.png"  />
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>克氏原螯虾产业技术体系首席科学家唐建清研...</h4>
							<span style="padding-left: 25px;"> </span>
						</span>
						<span class="right">
							<time>11-10</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
				<li>
					<!-- vertical-align: middle; -->
					<a @click="newsDetail(4)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/2021101917221029442f.jpg"  />
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>热烈欢迎！荆州高新区黄星书记来武汉总部考...</h4>
							<span style="padding-left: 25px;"></span>
						</span>
						<span class="right">
							<time>10-19</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
				<li>
					<!-- vertical-align: middle; -->
					<a @click="newsDetail(5)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/202009071357083546bf.png"  />
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>荆州高新区“十四五”发展规划课题小组实地考察调研武汉中易智能设备生产基地</h4>
							<span style="padding-left: 25px;"> 荆州高新区“十四五”发展规划课题调研小组一行，来到位于荆州高新区的武汉中易智能...</span>
						</span>
						<span class="right">
							<time>09-07</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
			</ul>
            <ul v-if="type == 2" class="news_zi_ul clearfix">
				<li>
					
					<a @click="newsDetail2(1)" class="clearfix" style="margin-bottom: 10px; ">
					
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>农业农村部召开推进渔业现代化建设现场会...</h4>
							<span style="padding-left: 25px;"></span>
						</span>
						<span class="right" style="height: auto;line-height: normal">
							<time>01-04</time>
							<i></i>
						</span>
					</a>
				</li>
				<li>
					<a @click="newsDetail2(2)" class="clearfix" style="margin-bottom: 10px; ">
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>全国内陆大水面生态渔业发展现场会在黑龙江大庆召开...</h4>
							<span style="padding-left: 25px;"></span>
						</span>
						<span class="right" style="height: auto;line-height: normal">
							<time>01-04</time>
							<i></i>
						</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,
   
    
  },
  beforeRouteUpdate(to,from,next){
    console.log(111111)
     this.detail = false;
    if(to.params.type != this.type){
      this.type = to.params.type;
    }
    next()
  },

  data(){
    return {
      // bannerUrl: ["20240220143932.png"],
      bannerUrl: ["201901161150090ddc9f.jpg"],
      productName:'公司动态',
      divStyle: '',
      imgStyle: '',
      bannerTitle: ["新闻中心","NEWS"],
      type: 1,
    }
  },
  watch: {
    type(item1,item2){
      if(item1 != item2){
        if(this.type == 1){
          this.productName = '公司动态'
        }else if(this.type == 2){
          this.productName = '行业新闻'
        }
      }
    },
  },

  created(){
    if(this.$route.params){
      this.type = this.$route.params.type;
    }
  },
  methods: {
    checkType(type){
      this.type = type;
    },
    newsDetail(index){
     this.$router.push({
          path:"/newsDetail/"+index
      })
    },
	newsDetail2(index){
     this.$router.push({
          path:"/newsDetail2/"+index
      })
    },
  }
}
</script>
  
<style>

    
    

</style>