<template>
    <div>
        <banner :bannerUrl="bannerUrl" ></banner>
        <div class="about_home">
		    <div class="about_com">
			    <div class="bar clearfix">
				    <span class="curbar"><i>您当前的位置：</i><a >关于我们</a> > <i>公司简介</i></span>
			    </div>
		    </div>
		    <div style="margin-top: 10px;">
			    <img src="../assets/images/20240110104916.png">
		    </div>
	    </div>
	    <div class="wrap container">
    	    <dl class="i_about clearfix">
                <dd  class="col-md-12 col-sm-12 col-xs-12">
            	    <h3>武汉市中易天地物联科技有限公司</h3>
                    <p  style="text-indent: 2em;"> 武汉市中易天地物联科技有限公司是一家以智慧渔业云平台为核心产品，为水产行业提供物联网一站式系统解决方案（简称中易智慧渔业系统）的高新技术企业。同时为客户提供在线智能水质监测仪、数据分析及解决方案等产品和服务。
					    中易物联在国家大力发展智慧农业、鼓励农业科技创新的大背景下，与国内外研究机构长期合作，拥有丰富的物联网传感器、软件及硬件开发技术，拥有累计超过百余款软硬件产品的开发经验，拥有专利及软件著作权数十项，具有极强的产品研发及迭代能力。
					    公司独立研发了拥有自主知识产权的中易智慧渔业系统，创新性将物联网技术应用到水产养殖领域，形成了集水质在线监测、智能控制和数据分析为一体的系统化解决方案。针对水产养殖风险大、水产品质量安全隐患等行业痛点，通过对溶解氧、PH值、水温、氨氮、叶绿素等多项参数的数据收集及系统分析，实现水产养殖精准控制，改变以往“靠经验、靠人力、靠天气”的养殖现状，实现水产行业向智慧养殖升级。
		                企业凭借深耕水产养殖数十年积累的养殖技术及理论体系，逐步形成了“以技术创新驱动企业成长 基于数据化提供模块化的高效益养殖系统解决方案”的产业战略布局。在全国各养殖集中区设立技术服务中心，推动实施数据化养殖。
				        中易物联致力于成为水产数据化养殖的领航者，不断向业界贡献创新的技术和解决方案，以开放的心态积极开展与水产行业上下游各领域的合作，通过构建水产真实的数据平台、精准的解决方案、养殖可追溯系统，实现为养殖从业者持续提升效益、为消费者提供安全水产品，以推动水产行业持续健康发展！推动我国由水产养殖大国向水产养殖强国转变！。  
				    </p>
                </dd>
			    <dd  class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
            	    <img src="../assets/images/20240119093745.png" />
                </dd>
            </dl>
        </div>
	    <div style="margin-top: 10px;">
		    <img src="../assets/images/20240110105607.png">
	    </div>
	    <div  class="project_home" style="margin-top: 10px;">
		    <div class="top" style="height: 60px;text-align: center;margin-top: 30px;position: relative;">
			    <span style="display: block;color: rgb(63, 63, 63);font-size: 24px;">技术优势</span>
			    <i style="font-size: 20px;text-align: center;position: absolute;top: 10px;transform: translate(-50%, -50%);color: #d7e2e840;">TECHNOLOGICAL ADVANTAGES</i> 
			    <em></em>
		    </div>
		    <ul class="clearfix">
			    <li class="clearfix">
				    <div class="pic_ca">
					    <a class="img1"><img src="../assets/images/2018122216421391c407.jpg" alt="" style="display: block;margin: auto"/></a>	
				    </div>
			    </li>
			    <li class="clearfix">
			    	<div class="pic_ca">
			    		<a class="img2"><img src="../assets/images/20181222164234a0d1e4.jpg" alt="" style="display: block;margin: auto"/>	</a>	
			    	</div>
			    </li>
			    <li class="clearfix">
			    	<div class="pic_ca">
			    		<a class="img3"><img src="../assets/images/2019011614175587ca15.jpg" alt="" style="display: block;margin: auto"/></a>
			    	</div>
			    </li>
		
		    </ul>
	    </div>
    </div>
</template>
  
<script>

import banner from "@/components/header/banner.vue"

import mynav from "@/components/nav/nav.vue"
export default {
   name:"AboutPage",
   data(){
    return{
     bannerUrl: ["20190116120101d6afbd.jpg"]
    }
   },
   components:{
       banner,
       
        mynav,
    }

}
</script>
  
<style>

    
    

</style>