<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>
    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>联系我们</span>
			</div>
			<ul class="nav_left">
				<li class="active">
					<a>联系方式</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a >联系我们</a> > <a >{{ productName}}</a> </span>
			</div>
         <div class="cont_zi">
					<div class="contact_map">
						<div id="map"></div>
					</div>
				<div class="con_cc">
					<div class="left">
						<img src="../assets/images/contact_wei.jpg" alt="" />
						<p>CONTACT US</p>
					</div>
					<ul class="right">
						<li class="clearfix">
							<b>公司名称：</b><span>武汉市中易天地物联科技有限公司</span>
						</li>
						<li class="clearfix">
							<b>电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：</b><span> 027-59764323  </span>
						</li>
						<li class="clearfix">
							<b>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</b><span> zywl@zytdwl.cn </span>
						</li>
						<li class="clearfix">
							<b>传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;真：</b><span>027-59764323</span>
						</li>
						<li class="clearfix">
							<b>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：</b><span>湖北省武汉市东湖开发区光谷大道303号光谷·芯中心E2-07栋801室

							</span>
						</li>
					</ul>
				</div>
			</div>
      
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"
import loadJs from '@/utils/map'

export default {
   name:"IndexPage",
   components:{
    
    Banner,
    MyNav,
  },
  data(){
    return {
      bannerUrl: ["201812231429346749bf.jpg"],
      productName:'联系方式',
      divStyle: '',
      imgStyle: '',
      bannerTitle: ["联系我们","CONTACT"]
    }
  },

  mounted(){
    this.initMap();
  },
  methods: {
   initMap(){
      //https://api.map.baidu.com/getscript?v=2.0&ak=6d88e38ab7f507ee19cec46443691d95&services=&t=20240108120844
     loadJs('https://api.map.baidu.com/getscript?v=2.0&ak=6d88e38ab7f507ee19cec46443691d95').then(()=>{
     // 百度地图API功能
		var map = new BMap.Map("map",{mapType: BMAP_HYBRID_MAP});    // 创建Map实例
		var point = new BMap.Point(114.459013,30.423331);   //坐标拾取网址：http://api.map.baidu.com/lbsapi/getpoint/index.html		
		var marker = new BMap.Marker(point);  // 创建标注
		var mapStyle = {style:"normal"};
	   map.setMapStyle(mapStyle);
		var top_left_control = new BMap.ScaleControl({anchor: BMAP_ANCHOR_TOP_LEFT});// 左上角，添加比例尺
		var top_left_navigation = new BMap.NavigationControl();  //左上角，添加默认缩放平移控件
		marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
		map.centerAndZoom(point, 18);  // 初始化地图,设置中心点坐标和地图级别
		map.addOverlay(marker);               // 将标注添加到地图中
		map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
		// map.addControl(new BMap.MapTypeControl());   //添加地图类型控件
		map.setCurrentCity("武汉");          // 设置地图显示的城市 此项是必须设置的
		window.onresize = function(){
		   map.centerAndZoom(point, 15);  // 重置窗口的时候 重新获取中心点坐标的位置
		}
	   map.addControl(top_left_control);
		map.addControl(top_left_navigation);
		/*map.addControl(top_right_navigation);  */		
     })
   },
    checkType(type){
      this.type = type;
    },
    dataDetail(index){
     this.$router.push({
          path:"/dataDetail/"+index
      })
    }
  }
}
</script>
  
<style>

    
    

</style>