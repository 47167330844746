<template>
    <header class="header">
	    <div class="kuan_heaaer clearfix container">
	    	<a @click="toindex" class="logo">
	    		<img src="../../assets/images/201901111531471afcf0.png" alt="" />
	    	</a>
	    	<div class="nav_div">
	    		<ul class="nav_ul">                                                                                                      
	    			<li class="active">
	    				<a @click="toindex"><span>首页</span></a>
	    			</li>
	    			<li>
	    				<a @click="toabout"><span>关于我们</span></a>
	    			
	    			</li>
					<li>
	    				<a @click="toproduct(1)"><span>产品中心</span></a>
						<div class="erji">
	    					<a @click="toproduct(1)">在线监测设备</a>
							<a @click="toproduct(2)">便携检测设备</a>
							<a @click="toproduct(3)">卵囊藻自动扩培机</a>
	    				</div>
	    			</li>
	    			<li>
	    				<a @click="todata"><span>数据中心</span></a>
	    				
	    			</li>
					<li>
	    				<a @click="tocase"><span>工程案例</span></a>
	    				
	    			</li>
	    				<li>
	    				<a @click="tonew(1)"><span>新闻中心</span></a>
	    				<div class="erji">
	    					<a @click="tonew(1)">公司动态</a>
	    					<a @click="tonew(2)">行业新闻</a>
	    				</div>
						</li>
	    			
	    				<li>
	    				<a @click="tocontentus"><span>联系我们</span></a>
	    			
	    			</li>
	    		</ul>
	    	</div>
	    	<a  href="#mmenu" class="iconfont phone-nav">
	    		 <span class="glyphicon glyphicon-align-justify"></span>
	    	</a>
        
	    </div>
  </header>
   <index-nav></index-nav>
</template>

<script>
 import IndexNav from "@/components/nav/indexnav.vue"

export default {
  name:"HeaderTitle",
  components: {
    IndexNav
  },

 data(){
  return{
      
  }
 },
 
 mounted() {
        // new Mmenu(document.querySelector('#menu'))

        document.addEventListener('click', evnt => {
            let anchor = evnt.target.closest('a[href^="#"]')
            if (anchor) {
                evnt.preventDefault()
            }
        })
    },
 methods:{
  toindex(){
      this.$router.push({
          path:"/index"
      })
  
  },
  toproduct(type){
      if(!type){
        type =1;
      }
      console.log(type)
      this.$router.push({
          path:"/product/"+type
      })
      
  },
  tonew(){
      this.$router.push({
          path:"/new"
      })
      
  },
  toabout(){
      this.$router.push({
          path:"/about"
      })
      
  },
  tocontentus(){
        this.$router.push({
            path: "/contentus"
        })
  },
  todata(){
    this.$router.push({
            path: "/data"
        })
  },
  tocase(){
    this.$router.push({
            path: "/case/1"
        })
  },
  tonew(type){
    this.$router.push({
            path:"/new/"+type
        })
  },
  
  

 },
  
}
</script>

<style scoped>

  
  

</style>
