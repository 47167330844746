<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>

    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>数据中心</span>
			</div>
			<ul class="nav_left">
				<li class="active" >
					<a @click="todata">行业数据</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a @click="todata">数据中心</a> > <a >{{ productName}}</a> </span>
			</div>
            <div v-if="index == 1" class="news_show_zi">
				<h4>蓝藻频繁爆发？多数和这个指标有关！</h4>
				<p>
					　　蓝藻，水产养殖池塘中恶魔般的存在。危害大、易爆发、不易处理，让人焦头烂额，甚至损失惨重。
				</p>
				<img src="../../assets/images/2019011614152931c68a.jpg" alt="" class="" />
				<p>
					江西上饶，鳝鱼养殖池溏，10亩，200口网箱，蓝藻爆发一周有余，鳝鱼吃食不好。
				蓝藻是原核生物，也是最简单、最原始的单细胞生物，因内含一种蓝色色素，因得名蓝藻。常见的蓝藻有蓝球藻（色球藻）
			、念珠藻、颤藻、微囊藻等。常见且危害最大的就是微囊藻，这个藻呈颗粒状组合体，漂浮在水体表层，严重时，在水面形成一
				层蓝绿色且有腥臭味的浮沫，也是就我们所说的“水华”。
				</p>
				<img src="../../assets/images/201901161418156f9297.jpg" alt="" class="" />
				<img src="../../assets/images/201901161421338b29f6.jpg" alt="" class="" />
				<p>
					蓝藻一旦爆发，直接导致水质恶化，最明显的就是pH值升高、溶氧早晚差异大，极易引起夜间缺氧。蓝藻的大量繁殖，也会影响其他藻类的正常生长，
					破坏生态平衡。同事，蓝藻死亡之后，也会释放大量有毒物质。
					轻则影响养殖动物正常摄食、脱壳、生长，增加饵料系数；重则因处理不当导致养殖动物中毒、大批量死亡，进而养殖亏本严重。蓝藻爆发的主要原因有：
					1、养殖水体富营养化：过量使用有机肥、过量投饵等；
					<br/>
					2、池塘生态紊乱：频繁使用杀虫、杀藻药物、滥用抗生素等；
					<br/>
					3、缺少碳源：蓝藻有伪空泡，可浮在水体表层，与空气接触的机会多，能利用空气中的二氧化碳，且能直接利用水中的碳酸氢盐作为碳源。当水体中碳源缺乏时，其它藻类如绿藻和硅藻因缺碳而不能生长，蓝藻就形成优势种群；
					<br/>
					4、营养失衡：过量使用化肥，氮磷比失衡（一般认为氮磷比为16：1）
				</p>
				<p><strong>所以，高温天气、高pH、有机质丰富的池塘，极易爆发蓝藻。</strong></p>
				<p>其实，有一个指标可以准确判断养殖池塘是否容易爆发蓝藻。</p>
				<p><strong>那就是总碱度！</strong></p>
				<p>总碱度，养殖水体中碳酸根和碳酸氢根离子的含量之和。总碱度决定了池塘的天然生产力，总碱度也代表水体中可供应的二氧化碳的总量，代表池塘的自养氮同化能力。</p>
				<p><strong>水产养殖正常总碱度要求在120-150mg/L之间。</strong></p>
				<p>上面讲到，蓝藻对二氧化碳具有很高的亲和力，同时能在高pH的环境下生长，所以，<strong>总碱度过低或过高都容易爆发蓝藻！</strong></p>
				<p>其中，总碱度过低还有如下表现：
					<br/>
					1、池塘水不好肥，就算好不容易肥起来，也很快就会倒藻，水容易浑浊；
					<br/>
					2、水体的缓冲性不够，pH值、溶氧日变化幅度巨大；
					<br/>
					3、花白鲢产量不高，养殖总产量也不高；
					<br/>
					4、碳氮比低，对氮的同化能力弱；
					<br/>
					5、容易长蓝藻等。
					<br/>
					基于总碱度这个指标，预防蓝藻的方法：
					<br/>
					1、养殖前期，可适量使用生石灰、小苏打提高池塘总碱度，；
					<br/>
					2、高温期，多开增氧机搅动水体，定期使用黄金益菌素B型、水博士调水、补充碳源，定期使用活肥补充微量元素。
				    <br/>
					<strong>所以，你的池塘是否高产、是否稳定、是否容易爆发蓝藻，检测在总碱度，一测便知！</strong>
				</p>		
			</div>
            <div v-if="index == 2" class="news_show_zi">
				<h4>南美白对虾标苗塘pH偏高处理方案</h4>
				<img src="../../assets/images/20240110182334.png" alt="" class="" />
				<img src="../../assets/images/20190116140140984798.jpg" alt="" class="" />
				<p>
					三、问题分析
					<br/>
					1.藻类为小球藻，单一藻相，水色很浓。单一的藻相会因为藻类统一的生理习性，导致光合作用非常强烈，从而使得pH持续偏高。
					<br/>
					2. 天气因素：高温季节，连续晴朗天气会导致水体光合作用强烈，从而令pH持续偏高
					<br/>
					3.晴天藻类光合作用过强，导致早晚PH变化较大，溶氧昼夜变化较大，藻类老化死掉为弧菌提供了较好的环境和营养，弧菌繁殖过快，使虾应激，吃料变慢。
				</p>
				<img src="../../assets/images/20240110182804.png" alt="" class="" />
				<img src="../../assets/images/20190116140140142c20.jpg" alt="" class="" />
				<p>六、案例总结</p>
				<p><strong>那就是总碱度！</strong></p>
				<p>pH偏高的危害有：碱中毒，会腐蚀表皮黏膜组织，导致鳃、肠功能障碍甚至坏死，不仅会导致慢大，还会缺氧死亡。
					pH高的环境若遇温度或盐度变化就会导致肌体渗透障碍，诱发气泡病，进而易感病菌或直接导致死亡。</p>
				<p><strong>水产养殖正常总碱度要求在120-150mg/L之间。</strong></p>
				<p>上面讲到，蓝藻对二氧化碳具有很高的亲和力，同时能在高pH的环境下生长，所以，<strong>总碱度过低或过高都容易爆发蓝藻！</strong></p>
				<p>养殖水体pH偏高处理：
					<br/>
					1.藻类繁殖过旺，pH变化较大，从水色来观察为水色偏浓。处理方法为：有外源水好的换部分水，同时施放有益微生物制剂，来抑制藻类的过度繁殖。
					<br/>
					2.水色正常但pH偏高，这种情况多数发生在养殖前期，主要原因为池塘老化、塘底含氮有机质偏多以及水体缓冲力低。处理方法为：先泼洒乳酸菌制剂和葡萄糖中和碱性物质，
					再使用腐殖酸钠提高水体缓冲力。
				</p>		
			</div>
			<div  v-if="index == 3" class="news_show_zi">
				<h4>水瘦导致加州鲈养殖池溏溶氧偏低处理</h4>
				<img src="../../assets/images/20240110183446.png" alt="" class="" />
				<img src="../../assets/images/20190116104725a5725f.jpg" alt="" class="" />
				<img src="../../assets/images/20240110183549.png" alt="" class="" />
				<img src="../../assets/images/20190116105214e6b799.jpg" alt="" class="" />
				
				<p>
					在6月29日，上午9：00左右到池塘边观察，水色明显好转，再结合氧曲线上分析，晴天随着温度的升高，最高溶氧、最低溶氧都有上升的趋势，说明用肥溶水精华素后，有助于藻类的繁殖，使用底改精华片后，
					减少底部氧债。通过水质检测，氨氮由1.2→0.6，亚盐由0.3→0.2，可见爽水精华液和底改精华片对氨氮、亚盐有一定的控制作用。
				</p>
				<p>六、案例总结</p>
				<p>1.爽水精华液的主要成分是高浓度有机酸（＞75%）和B族维生素，对氨氮亚盐，重金属等有害物质有络合，解毒作用，也能补充碳源，稳定水质，缓解养殖动物的应激反应。
					<br/>
					2.底改精华片的主要成分是过氧化硫酸氢钾复合盐，是一种强氧化剂，能有效分解池底的各种有机质，使池底保持富溶氧状态，从根本上解决氨氮、亚盐等物质的积累，另外其独特的抑菌功效可抑制池底厌氧和兼氧性细菌的繁殖，同时在阴雨天也可以使用。
					<br/>
				   3.肥水精华素的主要成分是硅酸盐和各种微量元素，能培养有益藻类，平衡藻相， 改善水色，抑制蓝藻、裸藻等有害藻类的过度繁殖；倒藻后使用，可使池水中有益藻类迅速成为优势种群，缓解藻毒素对鱼虾的危害。     
				   <br/>
				   4.藻类对池塘的溶氧有着举足轻重的影响，藻类的多与少就代表着池塘水质的肥与瘦，我们都知道，水瘦了池塘的产氧能力不足，氨氮、亚盐易偏高，这对养殖动物来说不是一件好事。通过中易智慧渔业系统可以看到水中溶氧的持续变化，一般来说，溶氧保持在5-12mg/L之间较好，若溶氧持续高于12mg/L，则要提前预防蓝藻或倒藻；若最高溶氧在6-8之前并持续走低，则说明水体偏瘦，藻类较少（或藻类开始老化），需要补充一些微量元素；若最低溶氧持续走低，则说明池塘底部积累了较多的有机质和有毒有害物质，导致底部氧债增加，此时最好进行底改处理。
				</p>		
			</div>		
        	<hr style="border: 0;border-top: 1px solid #d0d0d5;margin-bottom: 0px;">
			<ul class="prev_next clearfix" style="margin: 2% auto">
				<li><b>上一篇：</b><a @click="upPage">{{lastTitle}}</a></li>
				<li><b>下一篇：</b><a @click="downPage">{{ nextTitle }}</a></li>
			</ul>
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

export default {
    name:"dataDetail",
  components:{
    
    Banner,
    MyNav,    
  },
  beforeRouteUpdate(to,from,next){
    console.log(111111)
     
    if(to.params.type != this.type){
      this.type = to.params.type;
    }
    next()
  },

  data(){
    return {
    //   bannerUrl: ["20240220143932.png"],
	  bannerUrl: ["2018122313504180a5df.jpg"],
      productName:'行业数据',
      divStyle: '',
      imgStyle: '',
	  index: 1,
	  lastTitle: '',
	  nextTitle: '',
	  title: ["暂无信息！","蓝藻频繁爆发？多数和这个指标有关！","南美白对虾标苗塘pH偏高处理方案","水瘦导致加州鲈养殖池溏溶氧偏低处理","暂无信息！"],
	  bannerTitle: ["数据中心","DATA"]
    }
  },
  
  created(){
    if(this.$route.params){
      this.index = parseInt(this.$route.params.index);
	   console.log(this.$route.params)
	  this.lastTitle = this.title[this.index-1]
	  this.nextTitle = this.title[this.index+1]
	  console.log(this.index+1)
    }
  },
  methods: {
    todata(){
      this.$router.push({
          path: "/data"
      })
    },
    productDetail(type){
     this.$router.push({
          path:"/productDetail/"+type
      })
    },
	upPage(){
		if(this.index == 1){
			return
		}else {
			this.index = this.index-1
			this.lastTitle = this.title[this.index-1]
	        this.nextTitle = this.title[this.index+1]
		}
	},
	downPage(){
		if(this.index == 3){
			return
		}else {
			this.index = this.index+1
			this.lastTitle = this.title[this.index-1]
	        this.nextTitle = this.title[this.index+1]
		}
	},

  }
}
</script>
  
<style>

    
    

</style>