<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>
    <div class="com_zi clearfix">
		  <div class="left_zi">
			  <div class="top_com">
				  <span>新闻中心</span>
			  </div>
			  <ul class="nav_left">
				  <li class="active">
					  <a @click="toNews(1)">公司动态</a>
				  </li>
				  <li >
					  <a @click="toNews(2)">行业新闻</a>
				  </li>
			  </ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a @click="toNews(1)">新闻中心</a> > <a >{{ productName}}</a> </span>
			</div>
       <div v-if="index == 1" class="news_show_zi">
				<h4>新闻在线</h4>
        <div style="border-bottom:solid 1px #018ffb;margin-bottom: 20px;">
					  <p>
              <span>12-23</span><span style="padding-left: 15px;">来源：</span><span> 武汉中易</span>
            </p>
				</div>
				<p>
					【1】10月9日，设备事业部总经理余总、商务经理熊浚与中国电信股份有限公司丹江口分公司魏总在丹江口市裕水鲜生态农业科技有限
					公司董事长沈总邀请下，参观了裕水鲜设施渔业基地，余总对裕水鲜设施渔业基地的建设提出了一些建设性意见和思路，
					沈总对中易物联的专业性表示认可，为接下来双方的合作打下了良好的基础。
				</p>
				<img src="../../assets/images/20231214172220ee97cd.jpg" alt="" class="" />
				<p>
					【2】10月14日至15日，首届全国高校智慧渔业设计大赛暨智慧渔业人才培养高峰论坛在华中农业大学举行，养殖事业部李总、设备事业部余总、
					总部人事行政经理张婵、动保事业部数字渔业部负责人荣道斌、动保事业部人事经理邓春雪出席论坛，会上余总以“智慧渔业概述”为主题做了报告分享。
				</p>
				<p>
					15日下午，我司邀请了三十余位各大高校的水产精英师生到武汉中易丛霖养殖基地参观交流，由李总、余总负责进行养殖现场智能设备讲解及公司运营宣讲。
					在参观过程中，大家不仅亲眼目睹了基地的高科技养殖设施，还了解了现代化的养殖模式，并与各位领导及基地的技术人员进行了深入的交流和讨论。
					这是一次非常难得的机会，让这些才华横溢的年轻人能深入了解现代水产养殖业的发展趋势，以及如何应用最先进的科技手段来提高养殖效率和水产品品质。
					同时，这次活动也为武汉中易提供了一次展示自身实力和形象的机会，进一步提升了公司的知名度和影响力。我们相信，通过这样的交流活动，
					可以为推动水产养殖业的发展和进步做出积极的贡献，智慧渔业已经成为水产养殖的主流赛道，将更快促进渔业的发展和人才的培养！
				</p>
				<img src="../../assets/images/202312141722208964c4.jpg" alt="" class="" />
				<p>
					【3】10月16日，设备事业部销售部经理梁伟、许辉陪同公安科协贺主席及普及部任部长到公安养殖基地参观，基地负责人吴久伍介绍了基地的养殖情况以及相关的数字化建设工作，
					贺主席对基地的整体情况表示了肯定，最后双方就基地申报“科技小院”项目达成一致，公安科协将大力支持基地的项目申报并提供相应的协助。
				</p>
			</div>
       <div v-if="index == 2" class="news_show_zi">
				  <h4>聚力远航 蝶变升华 ——水世纪集团“951计划”启动</h4>
          <div style="border-bottom:solid 1px #018ffb;margin-bottom: 20px;">
					  <p>
              <span>12-27</span><span style="padding-left: 15px;">来源：</span><span> 武汉中易</span>
            </p>
				  </div>
				<p>
					　　十五载征程，世纪再启航！
				</p>
				<p>
					　　2021年12月21日上午9点整，让人期待的的水世纪集团951计划高绩效运营体系启动仪式在集团总部会议室拉开帷幕。
				</p>
				<img src="../../assets/images/20211227172910a7a957.jpg" alt="" class="" />
				<p>
					　　水世纪集团自2006年成立以来，经历了创业成长期、快速发展期，也遇到了所有企业都会遇到的瓶颈期。集团领导早就意识到这个问题，也高瞻远瞩进行了重新设计，更是在当前国家经济发展战略调整，全面实现乡村振兴、大力发展数字渔业的大背景之下，准确把握集团未来的发展战略，抓住时代赋予我们新的历史发展机会，为实现集团再次跨越，做出了坚定的决策：与外部咨询机构合作，改变与完善公司的运营体系，推动公司的整体绩效提升，为国家、
					为社会创造新的价值，再一次让水世纪腾飞，让所有员工获得更多幸福感。
				</p>
				<img src="../../assets/images/20211227172910f13c72.jpg" alt="" class="" />
				<p>
					本次启动仪式由集团启智学院院长廖正娟老师主持，集团各领导、武汉办公室全员、动保事业部以及荆州中易、湖北紫荆等公司部分同事一起见证了本次意义重大的启动仪式。
				</p>
				    <img src="../../assets/images/20211227173006fa1aac.jpg" alt="" class="" />
				    <p>
					    集团董事长唐总首先做了项目启动动员报告，具体阐述“951计划”的核心是要用9年时间，覆盖500万亩水面，实现智慧渔业第一品牌的第二次公司发展跨越计划。紧接着，从集团现状分析、愿景规划、运营管理等各方面向大家诠释了集团启动本次高绩效运营体系的初衷、
					    目标及引入专业管理咨询公司的必要性，要求大家高度重视、积极参与、汇聚力量、共创辉煌。
				    </p>
				    <img src="../../assets/images/20211227172910c5bb82.jpg" alt="" class="" />
				    <p>
					    其后，北京海蓝蓝管理咨询的李霄峰老师激情发言，从第三方的角度深度分析了集团的发展状况及运营管理体系优化提升方向，要激发个人能力向企业能力转化。李老师对水世纪的未来充满信心，选择水世纪、链接水世纪，期望能通过全面深入合作实现水世纪集团的再次腾飞。接下来，集团李总、余总、杨总也分别发言，表达了对本次管理咨询合作的期望与信心。
					    最后，由集团各领导与李老师共同宣布：水世纪集团951计划高绩效运营体系启动仪式正式启动！
				    </p>
				    <img src="../../assets/images/20211227173014b958cc.jpg" alt="" class="" />
				    <img src="../../assets/images/20211227172910b0b10d.jpg" alt="" class="" />
				    <p>
					    （图示：启动会合影留念，灿烂笑容彰显集团全体员工推进951计划的坚定信心）
				    </p>
				    <p>
					    高绩效运营管理将围绕“简单、实用、共享、共荣”展开，这将对集团的组织架构、业务流程、激励机制、
					    运营管理、市场推广等方面进行全方位优化提升，激发组织活力、人才潜能、运行效率，计划的成功实施意义重大！希望我们所有员工共同努力，藉由951计划的助力，
					    实现集团的蝶变升华，再次腾飞！
				    </p>
			 </div>
      <div v-if="index == 3" class="news_show_zi">
				<h4>克氏原螯虾产业技术体系首席科学家唐建清研究员来厂参观指导</h4>
        <div style="border-bottom:solid 1px #018ffb;margin-bottom: 20px;">
					  <p>
              <span>11-10</span><span style="padding-left: 15px;">来源：</span><span> 武汉中易</span>
            </p>
				</div>
				<p>
					　　2021年10月14日江苏省淡水水产研究所、江苏省克氏原鳌虾产业技术体系首席专家唐建清老师在集团李总和集团运营总经理杨总的陪同下，
					参观了荆州中易的智能设备生产基地。
				</p>
				<img src="../../assets/images/202111100957543800b1.png" alt="" class="" />
				<p>
					　　李总详细讲述了集团公司的经营理念、使命、愿景，重点阐述了集团唐总提出的“双养理论”。介绍了公司在自主创新、技术研发和产品应用等方面的情况，以及结合整个行业发展趋势、国家政策等方面企业的思考和探索。李总和杨总同时向专家详细介绍了P1便携式检测仪、
					Y53在线监测控制仪、L201智能光反应器、T100无人艇一系列设备的功能及实际应用价值，以及大数据智能养殖理念的传播及推广。
				</p>
				<img src="../../assets/images/2021111009575491f2e6.png" alt="" class="" />
				<p>
					通过智能在线监测系统对水体中核心指标进行收集、分析，进而指导养殖的模式获得了专家老师的高度肯定，专家老师期望这种模式尽快推广普及，为水产行业和社会带来更好的效益。
				</p>
			</div>
      <div v-if="index == 4" class="news_show_zi">
				<h4>热烈欢迎！荆州高新区黄星书记来武汉总部考察交流</h4>
        <div style="border-bottom:solid 1px #018ffb;margin-bottom: 20px;">
					  <p>
              <span>10-19</span><span style="padding-left: 15px;">来源：</span><span> 武汉中易</span>
            </p>
				</div>
				<p>
					　　2021年9月25日荆州市委副秘书长、高新区党工委书记黄星、管委会副主任肖西根一行来公司总部武汉光谷芯中心考察交流。
                  首先中易物联副总经理余峰华先生向嘉宾介绍了芯中心办公地的整体职能情况，重点介绍了水产物联网的发展情况、中易物联在技术上
				  的领先优势和全国水产养殖的应用展示，通过水上摄像头监控、水面智能设备操作、水下传感器实时数据收集，实现智慧渔业的高效、健康养殖模式。
				</p>
				<img src="../../assets/images/20211019172210cdf3e9.jpg" alt="" class="" />
				<p>
					　　唐总全面介绍了集团的整体情况及集团在种苗、养殖、饲料、动保、设备等方面的布局。中易物联作为一家物联网高科技企业，
					紧紧跟随时代发展的步伐，抓住了国家新的战略发展方向，利用“物联网+”的大好机遇，打造新时代智慧渔业大平台。运用公司建立的高效、
					健康、低碳养殖模式，生产出更多的优质水产品，同时依据公司的大数据平台，对更多的养殖品种、养殖区域、养殖环境、养殖过程等进行数据监测
					、收集与整理，形成更准确的数据标准，帮助养殖降低风险，形成可追溯水产品养殖体系，保证水产品安全。同时也通过“物联网+”，实现与政府、
					院校、企业（动保、饲料、食品加工）、养殖户等全方位合作。前段时间应邀去荆州高新区交流考察，双方共同讨论了在高新区建设数字渔业示范园，
					通过渔业大数据平台建设，打通从养殖端到消费端的食品安全追溯体系；通过3000亩数字渔业养殖示范园展示数据化养殖的成果，
					打造安全水产品品牌；相信随着数字渔业示范园的运行，必将推动荆州淡水渔都更上一层楼。
				</p>
				<img src="../../assets/images/202110191722106610ac.jpg" alt="" class="" />
				<p>
					黄星书记对中易物联的发展予以了高度肯定，同时希望中易物联能够在荆州设立智能设备研发中心，数据渔业研究院。从技术体系、
					智能设备生产制造和渔业数据体系建设，形成荆州的区域优势；整合政府、地方更多资源来做，做成配套，寻求养殖、设备的政策支持，
					保障可行性，形成企业运行模式；争取更多的融资与补贴，制定特殊推广政策，形成市场化运作，打造荆州的服务体系平台。
				</p>
				<img src="../../assets/images/2021101917221029442f.jpg" />
			</div>
      <div v-if="index == 5" class="news_show_zi">
				<h4>荆州高新区“十四五”发展规划课题小组实地考察调研武汉中易智能设备生产基地</h4>
        <div style="border-bottom:solid 1px #018ffb;margin-bottom: 20px;">
					  <p>
              <span>09-07</span><span style="padding-left: 15px;">来源：</span><span> 武汉中易</span>
            </p>
				</div>
				<img src="../../assets/images/202009071357083546bf.png" alt="" class="" />
				<p>
					　　（调研考察组一行抵达武汉中易智能设备生产基地）
				</p>
				<p>
					2020年8月20日下午，荆州高新区“十四五”发展规划课题调研小组一行，来到位于荆州高新区的武汉中易智能设备生产基地考察调研。
					课题执行顾问、北京城市发展研究院副院长王鸿；课题组组长、中国海洋大学管理学院张立波；中国农业产业资产管理平台首席顾问何毅；
					北京北达城乡规划设计研究院研究中心主任张云飞；中国海洋大学数字产业研究院助理研究员吴倩；北京大学荆州高新区体育产业研究基地
					研究员何文义；产业经济课题助理研究员关卓伦；产业经济课题助理研究员孙雯雯；荆州高新区陪同人员经济发展和科技创新局副局长李平
					等参与本次考察调研。
				</p>
				<img src="../../assets/images/20200907135708de5b78.png" alt="" class="" />
				<p>
					（李总向调研考察组一行介绍）
				</p>
				<p>
					　　武汉中易总经理李刚先生陪同调研考察组一行人员，向调研组人员讲解介绍了公司在自主创新、技术研发和产品应用等方面的情况介绍，
					以及结合整个行业发展趋势、响应国家环保政策等方面介绍了企业的思考和探索。
				</p>
				<img src="../../assets/images/202009071357087a80a9.png" alt="" class="" />
				<p>
					（调研考察组成员对公司自主编制的《2019水产大数据白皮书》非常感兴趣，纷纷驻足取阅）
				</p>
				<img src="../../assets/images/202009071357076c6748.png" alt="" class="" />
				<p>
					（李总介绍中易自主研发的智能设备）
				</p>
				<p>
					李总为调研组人员详细讲解，P1便携式检测仪、Y23在线监测控制仪以及L201智能光反应器等设备的功能及实际应用的价值，
					以及大数据智能养殖的理念的传播及推广。
				</p>
				<img src="../../assets/images/202009071357080c3583.png" alt="" class="" />
				<p>
					（李总向考察调研组一行介绍卵囊藻和光反应器）
				</p>
				<p>
					李总向考察调研一行详细介绍了公司的使命、愿景及战略定位，并重点介绍了企业的技术指导理论“双养理论”，
					详细阐述了智慧渔业水平衡的重要意义和价值，水平衡对于提升养殖效益，助力产业升级，以及推进智慧渔业落地发展都有非常重要的作用。
					本次课题组组长、中国海洋大学管理学院张立波教授在听完介绍之后，对企业倡导和践行的“大数据思维”和“用数据指导水产养殖”高度认可，
					并表示以卵囊藻改善水质，用智能监控设备收集各类水环境信息大数据做养殖指导，形成了一套完整的生态平衡养殖产业链系统，
					不仅符合近年来国家对生态环境大保护的政策，也顺应了即将到来的5G时代万物互联的时代潮流。
				</p>
				<p>
					“十四五”时期是我国全面建成小康社会、实现第一个百年奋斗目标之后，乘势而上开启全面建设社会主义现代化荆州高新区新征程、
					向第二个百年奋斗目标进军的第一个五年，做好“十四五”规划编制工作意义重大。
				</p>
				<p>
					此次课题调研组积极响应习主席“把加强顶层设计和坚持问计于民统一起来，齐心协力把“十四五”规划编制好”的号召，深入一线实地考察调研，表示此次调研收获丰富，
					武汉中易在智慧渔业领域的实践和探索经验值得借鉴，为荆州高新区“十四五”发展规划智慧农业、生态发展提供了有益的启发。
				</p>
			</div>
      <hr style="border: 0;border-top: 1px solid #d0d0d5;margin-bottom: 0px;">
			<ul class="prev_next clearfix" style="margin: 2% auto">
				<li><b>上一篇：</b><a @click="upPage">{{lastTitle}}</a></li>
				<li><b>下一篇：</b><a @click="downPage">{{ nextTitle }}</a></li>
			</ul>
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,
   
    
  },
  // beforeRouteUpdate(to,from,next){
  //   console.log(111111)
  //    this.detail = false;
  //   if(to.params.type != this.type){
  //     this.type = to.params.type;
  //   }
  //   next()
  // },

  data(){
    return {
       bannerUrl: ["201901161150090ddc9f.jpg"],
      productName:'公司动态',
      divStyle: '',
      imgStyle: '',
      bannerTitle: ["新闻中心","NEWS"],
      index: 1,
	    lastTitle: '',
	    nextTitle: '',
	    title: ["暂无信息！","新闻在线","聚力远航 蝶变升华 ——水世纪集团“951计划”启动","克氏原螯虾产业技术体系首席科学家唐建清研究员来厂参观指导","热烈欢迎！荆州高新区黄星书记来武汉总部考",
      "荆州高新区“十四五”发展规划课题小组实地考察调研武汉中易智能设备生产基地","暂无信息！"],
    }
  },
  
  created(){
    if(this.$route.params){
      this.index = parseInt(this.$route.params.index);
	    this.lastTitle = this.title[this.index-1]
	    this.nextTitle = this.title[this.index+1]
	    console.log(this.index+1)
    }
  },
  methods: {
    toNews(type){
      this.$router.push({
          path: "/new/"+type
      })
    },
   
	  upPage(){
		  if(this.index == 1){
			  return
		  }else {
			  this.index = this.index-1
			  this.lastTitle = this.title[this.index-1]
	      this.nextTitle = this.title[this.index+1]
		  }
	  },
	  downPage(){
		  if(this.index == 5){
			  return
		  }else {
			  this.index = this.index+1
			  this.lastTitle = this.title[this.index-1]
	      this.nextTitle = this.title[this.index+1]
		  }
	  },
  }
}
</script>
  
<style>

    
    

</style>