<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>
    <div class="com_zi clearfix">
		  <div class="left_zi">
			  <div class="top_com">
				  <span>新闻中心</span>
			  </div>
			  <ul class="nav_left">
				  <li >
					  <a @click="toNews(1)">公司动态</a>
				  </li>
				  <li class="active">
					  <a @click="toNews(2)">行业新闻</a>
				  </li>
			  </ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a @click="toNews(1)">新闻中心</a> > <a >{{ productName}}</a> </span>
			</div>
            <div v-if="index == 1" class="news_show_zi">
				  <h4>农业农村部召开推进渔业现代化建设现场会</h4>
          		  <div style="border-bottom:solid 1px #018ffb;margin-bottom: 20px;">
					<p>
              			<span>2024-01-04</span><span style="padding-left: 15px;">来源：</span><span> 农业农村部新闻办公室</span>
           			</p>
				  </div>
				  <p style="text-indent: 2em;">
						10月19日，农业农村部在湖北省武汉市召开推进渔业现代化建设现场会，总结交流各地经验做法，分析研判新形势新要求，
						研究部署加快推进渔业现代化重点工作。农业农村部副部长马有祥出席会议并讲话。
				  </p>
				  <p style="text-indent: 2em;">
						会议强调，要贯彻落实党的二十大精神和习近平总书记关于渔业的重要指示批示精神，锚定建设农业强国目标，
						以推进渔业现代化建设统领渔业渔政工作，以科技创新和深化改革为动力，
						以高质量发展、高水平保护和高效能治理为总要求，推动渔业实现产出高效、产品安全、资源节约、环境友好、治理有效。
				  </p>
				  <p style="text-indent: 2em;">
						会议指出，加快推进渔业现代化必须守牢水产品稳产保供和质量安全两条底线，守牢渔业安全生产和生态环境保护两条红线，
						强化渔业水域和水产种业两个要害，强化国际渔业合作和渔业治理两个关键点，并从养殖、捕捞、远洋、设施渔业等十个方面提出了
						重点工作任务，从改革创新、政策支持、科技支撑、协同机制、作风建设等方面明确了具体要求。
				  </p>
				  <p style="text-indent: 2em;">
						湖北省委常委、统战部部长宁咏出席会议。外交部、财政部、自然资源部、生态环境部、交通运输部、中国海警局等部门，农业农村部相关司局、
						直属单位，各省（区、市）、新疆生产建设兵团渔业主管部门，重点地区渔政执法机构，渔业相关高校、社团组织等有关单位的代表参加了会议。
				  </p>
	        </div>
       		<div v-if="index == 2" class="news_show_zi">
			<h4>全国内陆大水面生态渔业发展现场会在黑龙江大庆召开</h4>
        	<div style="border-bottom:solid 1px #018ffb;margin-bottom: 20px;">
				<p>
              		<span>2024-01-04</span><span style="padding-left: 15px;">来源：</span><span> 农业农村部新闻办公室</span>
            	</p>
			</div>
			<p style="text-indent: 2em;">
				12月26日，农业农村部渔业渔政管理局在黑龙江省大庆市召开全国内陆大水面生态渔业发展现场会，
				总结交流各地发展大水面生态渔业的经验做法，分析面临形势和存在问题，部署下一步促进大水面生态渔业发展的总体思路和重点任务。
			</p>
			<p style="text-indent: 2em;">
				会议指出，湖泊水库等大水面是重要的自然资源，大水面生态渔业是内陆淡水渔业的重要组成部分。近年来，各地认真贯彻落实习近平总书记
				关于树立大食物观的重要讲话精神，全国大水面生态渔业在政策措施出台、空间布局优化、科技支撑保障、典型模式示范、三产融合推进等方面
				取得积极成效，在保障产品供给、维护生态健康、促进乡村振兴、带动渔民增收等方面发挥了重要作用。
			</p>
			<p style="text-indent: 2em;">
				会议强调，发展大水面生态渔业是树立大食物观、构建多元化食物供给体系的重要内容，是推动绿色发展、促进人与自然和谐共生的有效途径，
				是推进乡村振兴、加快实现农业农村现代化的有力举措，是促进农民增收、实现人民对美好生活向往的必然选择。发展大水面生态渔业要处
				理好渔业生产和生态保护、渔业生产和其他利用、积极推动和规范利用、渔业发展和渔区振兴、渔业生产和融合发展等关系。
			</p>
			<p style="text-indent: 2em;">
				会议要求，要统筹发展和安全，树立大农业观、大食物观，践行“两山”理念，以满足人民群众对优美水域生态环境和优质水产品等美好生活需求为
				目标，加快推动形成科学合理、规范有序的大水面生态渔业发展空间格局和生产方式。坚持规划引领，科学确定发展区域，遵循“一水一策”，
				逐步推行容量管理，不断加强生产监管，推进三产融合，积极构建全产业链，强化科技支撑和成果应用推广，落实监管协同促进共建共治共享。
			</p>
			<p style="text-indent: 2em;">
				黑龙江、浙江、江西、山东、湖北、青海等6省及吉林查干湖作典型交流发言，大水面生态渔业科技创新联盟专家作有关研究进展情况报告。
				生态环境部、水利部等部门，农业农村部相关司局、直属单位，各省（自治区、直辖市）、新疆生产建设兵团渔业主管部门有关负责人参加会议。
				会议还组织观摩了大水面生态渔业产品生产、仓储暂养、加工等现场。
			</p>
	   		</div>
            <hr style="border: 0;border-top: 1px solid #d0d0d5;margin-bottom: 0px;">
			<ul class="prev_next clearfix" style="margin: 2% auto">
				<li><b>上一篇：</b><a @click="upPage">{{lastTitle}}</a></li>
				<li><b>下一篇：</b><a @click="downPage">{{ nextTitle }}</a></li>
			</ul>
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,
   
    
  },
  // beforeRouteUpdate(to,from,next){
  //   console.log(111111)
  //    this.detail = false;
  //   if(to.params.type != this.type){
  //     this.type = to.params.type;
  //   }
  //   next()
  // },

  data(){
    return {
      bannerUrl: ["201901161150090ddc9f.jpg"],
      productName:'行业新闻',
      divStyle: '',
      imgStyle: '',
      bannerTitle: ["新闻中心","NEWS"],
      index: 1,
	    lastTitle: '',
	    nextTitle: '',
	    title: ["暂无信息！","农业农村部召开推进渔业现代化建设现场会","全国内陆大水面生态渔业发展现场会在黑龙江大庆召开","暂无信息！"],
    }
  },
  
  created(){
    if(this.$route.params){
      this.index = parseInt(this.$route.params.index);
	    this.lastTitle = this.title[this.index-1]
	    this.nextTitle = this.title[this.index+1]
	    console.log(this.index+1)
    }
  },
  methods: {
    toNews(type){
      this.$router.push({
          path: "/new/"+type
      })
    },
   
	  upPage(){
		  if(this.index == 1){
			  return
		  }else {
			  this.index = this.index-1
			  this.lastTitle = this.title[this.index-1]
	          this.nextTitle = this.title[this.index+1]
		  }
	  },
	  downPage(){
		  if(this.index == 2){
			  return
		  }else {
			  this.index = this.index+1
			  this.lastTitle = this.title[this.index-1]
	          this.nextTitle = this.title[this.index+1]
		  }
	  },
  }
}
</script>
  
<style>

    
    

</style>